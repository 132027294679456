.w-16 {
  width: 16.66%
}

.w-20 {
  width: 20%
}

.w-33 {
  width: 33%
}

.input-group-text {
  background-color:#FFF;
}